import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CameraRetake } from '../icons/CameraRetake.svg';
import { ReactComponent as CameraValidate } from '../icons/CameraValidate.svg';

const PicturePreview = () => {
  const navigate = useNavigate();

  const reset = () => {
    localStorage.removeItem('capturedImage');
    navigate('/');
  };

  const validate = async () => {
    const url = process.env.REACT_APP_DISCORD_WEBHOOK_URL;
    const img = await axios.get(localStorage.getItem('capturedImage'), { responseType: 'blob' });
    const blob = new File([img.data], new Date().toISOString() + '.png', { type: 'image/png' });
    axios.post(url,  {
      file1: blob,
      payload_json: JSON.stringify({
        username: 'TechUp Connect',
        avatar_url: 'https://cdn.discordapp.com/app-icons/1076971559211774073/a53b36b4251221f04727c594466c4ec6.png',
        embeds: [
          {
            color: 0xFBD704,
            title: '📸  New Photo Taken @ VivaTeam HQ',
            description:  '🫵🏻  It\'s time to take yours at the TechUp corner!',
            fields: [
              {
                name: "❤️  Share it on social media and tag us!",
                value: "@techupnetwork"
              }
            ]
          }
        ]
      })
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
        console.log(`Successfully sent ${blob.name} to Discord!`);
        reset();
    })
    .catch(error => {
        console.error(`Error sending ${blob.name} to Discord:`, error);
    });
  };

  return (
    <div className="relative h-[90vh] w-[95vw] rounded-2xl overflow-hidden">
      <div className="absolute inset-0 bg-cover bg-center">
        <img src={localStorage.getItem('capturedImage')} alt="capturedImage" />
      </div>
      <div className="absolute bottom-0 left-0 right-0 flex justify-center pb-10 gap-x-6">
        <button onClick={reset} className="bg-white rounded-full w-20 h-20 p-3 flex justify-center items-center">
          <CameraRetake className='h-12 w-12'/>
        </button>
        <button onClick={validate} className="bg-white rounded-full w-20 h-20 p-3 flex justify-center items-center">
          <CameraValidate className='h-12 w-12 fill-green-500'/>
        </button>
      </div>
    </div>
  );
};

export default PicturePreview;
