import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Camera from './components/Camera';
import PicturePreview from './components/PicturePreview';

function App() {
  return (
    <Router>
      <div className="flex justify-center items-center h-screen bg-gradient-to-br from-yellow-400 via-yellow-600 to-pink-600">
        <Routes>
          <Route path="/" element={<Camera />} />
          <Route path="/preview" element={<PicturePreview />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
